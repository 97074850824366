<template>
    <div
        v-if="show"
        class="bf-lock"
        :class="{
            '--left': left,
            '--right': right,
        }"
    >
        <LockIcon />
    </div>
</template>

<script>
import LockIcon from "@/components/icons/LockIcon";

export default {
    name: "Lock",
    components: { LockIcon },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.bf-lock {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffffff;
    opacity: 0.8;

    padding: 0 10px;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.--left {
        justify-content: flex-start;
    }

    &.--right {
        justify-content: flex-end;
    }
}
</style>
