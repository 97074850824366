<template>
    <div v-if="globalOverlay" class="global-overlay">
        <b-spinner class="global-overlay__spinner" variant="success" />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { BSpinner } from "bootstrap-vue";

export default {
    name: "GlobalOverlay",
    components: {
        BSpinner,
    },
    computed: {
        ...mapGetters("app", ["globalOverlay"]),
    },
    methods: {
        ...mapMutations("app", ["setGlobalOverlay"]),
    },
};
</script>

<style scoped>
.global-overlay {
    position: fixed;
    background-color: #ffffff;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;

    display: flex;
    align-items: center;
    justify-content: center;
}

.global-overlay__spinner {
    width: 3rem;
    height: 3rem;
}
</style>
