import i18n from "@/libs/i18n/index.js";

export default [
    {
        id: 1,
        code: "less-30-min",
        name: "",
        desc: i18n.t("quiz.8.option.less-30-min"),
    },
    {
        id: 2,
        code: "30-60-min",
        name: "",
        desc: i18n.t("quiz.8.option.30-60-min"),
    },
    {
        id: 3,
        code: "more-60-min",
        name: "",
        desc: i18n.t("quiz.8.option.more-60-min"),
    },
];
