import Model from "@/models/Model";
import Cookie from "@/models/Cookie";

let userCurrency = Cookie.get("currency");
const CURRENCY = userCurrency ? userCurrency.toLowerCase() : "usd";

const currency = {
    usd: "$",
    pln: "zł",
    byn: "₽",
    eur: "€",
};

/**
 * @property {String} id
 *
 * @property {Boolean} active
 * @property {Number} aggregate_usage
 * @property {Number} amount
 * @property {String} amount_decimal
 * @property {String} billing_scheme
 * @property {Number} created
 * @property {String} currency
 * @property {Number} interval
 * @property {Number} interval_count
 * @property {Boolean} livemode
 * @property {Number} metadata
 * @property {Number} nickname
 * @property {String} object
 * @property {String} product
 * @property {Number} tiers_mode
 * @property {Number} transform_usage
 * @property {Number} trial_period_days
 * @property {Number} usage_type
 *
 * @property {Object} recurring
 * @property {String} aggregate_usage
 * @property {String} recurring.interval
 * @property {String} recurring.interval_count
 * @property {String} recurring.trial_period_days
 * @property {String} recurring.usage_type
 *
 * @property {Number} unit_amount
 * @property {Number} unit_amount_decimal
 *
 * @property {Object} currency_options
 */
export default class Plan extends Model {
    constructor(data = {}) {
        super(data);
        this.currency_options = {};

        this.fill(data);
        this.rate = 1;
    }

    setPriceCurrency(currency) {
        this.priceCurrency = currency;
    }

    get price() {
        return {
            unit: Math.floor(this.fullPrice / 100),
            subunit: this.fullPrice % 100,
        };
    }

    get fullPrice() {
        const obj = this.currency_options[CURRENCY];

        if (!obj) return this.object === "price" ? this.unit_amount : this.amount;

        return obj.unit_amount;
    }

    ratePrice(rate = 1) {
        this.rate = rate;

        return {
            unit: Math.floor((this.fullPrice * rate) / 100),
            subunit: +(this.fullPrice * rate).toFixed(0) % 100,
        };
    }

    get pricePerDay() {
        const { interval, interval_count } = this.recurring;

        let days = 1;

        switch (interval) {
            case "week":
                days = 7;
                break;
            case "month":
                days = 30;
                break;
            default:
                break;
        }

        days *= interval_count;

        return {
            unit: Math.floor(this.fullPrice / days / 100),
            subunit: Math.floor(this.fullPrice / days) % 100,
        };
    }

    get name() {
        return this.nickname;
    }

    isTrial() {
        const days = this.recurring.trial_period_days;
        return days && days > 0;
    }

    set name(value) {
        this.nickname = value;
    }

    get currencyCode() {
        const obj = this.currency_options[CURRENCY];

        if (!obj) return this.currency || "usd";

        return CURRENCY;
    }

    get currencySymbol() {
        const obj = this.currency_options[CURRENCY];

        if (!obj) return "$";

        return currency[CURRENCY] || "$";
    }

    get default() {
        return this.hasMetadata() && !!this.metadata["default"];
    }

    hasMetadata() {
        return Object.keys(this.metadata || {}).length > 0;
    }
}
