import { render, staticRenderFns } from "./LockIcon.vue?vue&type=template&id=3b7d1a71&scoped=true&"
import script from "./LockIcon.vue?vue&type=script&lang=js&"
export * from "./LockIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7d1a71",
  null
  
)

export default component.exports