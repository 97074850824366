<template>
    <svg
        class="svg-icon --fill"
        height="64.000000pt"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 64.000000 64.000000"
        width="64.000000pt"
    >
        <g stroke="none" transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)">
            <path
                d="M266 615 c-14 -14 -34 -25 -50 -25 -16 0 -26 -5 -26 -15 0 -10 -10
-15 -34 -15 -19 0 -45 -9 -60 -21 l-26 -20 0 -244 c0 -241 0 -244 22 -259 33
-23 423 -23 456 0 22 15 22 18 22 259 l0 244 -26 20 c-15 12 -41 21 -60 21
-24 0 -34 5 -34 15 0 10 -10 15 -26 15 -16 0 -36 11 -50 25 -15 16 -34 25 -54
25 -20 0 -39 -9 -54 -25z m90 -30 c10 -15 26 -25 40 -25 20 0 24 -5 24 -30 l0
-30 -100 0 -100 0 0 30 c0 25 4 30 24 30 14 0 30 10 40 25 9 14 25 25 36 25
11 0 27 -11 36 -25z m-164 -82 l3 -28 125 0 125 0 3 28 c3 21 8 27 28 27 14 0
34 -5 45 -10 18 -10 19 -24 19 -244 0 -204 -2 -235 -16 -240 -20 -8 -388 -8
-408 0 -14 5 -16 36 -16 240 0 256 -1 253 62 254 22 0 27 -5 30 -27z"
            />
            <path
                d="M212 398 c-17 -17 -24 -20 -34 -10 -7 7 -16 12 -20 12 -15 0 -8 -27
11 -44 19 -17 21 -17 55 9 36 27 47 55 23 55 -7 0 -23 -10 -35 -22z"
            />
            <path
                d="M297 383 c-19 -18 1 -23 93 -23 82 0 100 3 100 15 0 12 -17 15 -93
15 -52 0 -97 -3 -100 -7z"
            />
            <path
                d="M213 278 c-19 -18 -24 -19 -38 -8 -20 16 -33 5 -19 -17 23 -37 42
-39 74 -8 31 30 39 55 17 55 -7 0 -22 -10 -34 -22z"
            />
            <path
                d="M297 263 c-19 -18 1 -23 93 -23 82 0 100 3 100 15 0 12 -17 15 -93
15 -52 0 -97 -3 -100 -7z"
            />
            <path
                d="M222 158 c-19 -20 -25 -21 -43 -10 -25 16 -37 7 -23 -15 24 -38 42
-39 78 -4 20 19 33 38 30 43 -8 13 -18 10 -42 -14z"
            />
            <path
                d="M293 128 c2 -9 31 -13 97 -13 66 0 95 4 98 13 3 9 -21 12 -98 12 -77
0 -101 -3 -97 -12z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "ClipBoardIcon",
};
</script>

<style scoped></style>
