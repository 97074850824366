import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/routes";

import { getUser, isUserLoggedIn } from "@/auth";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

const unlockedRoutes = [
    "dashboard",
    "settings",
    "settings-language",
    "settings-subscription",

    "plans",
    "payment",
    "payment-result",

    "privacy-policy",
    "terms-and-conditions",
    "refund-policy",
];

router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn();

    if (isLoggedIn && to.name === "app") {
        return next();
        // return next( { name: "dashboard" } );
    }

    const user = getUser();
    if (isLoggedIn && user.accessDenied) {
        if (!unlockedRoutes.includes(to.name)) {
            return next({ name: "dashboard" });
        }
    }

    if (to.name === "auth-login") {
        if (isLoggedIn) {
            return next({ name: "dashboard" });
        }
    } else {
        if (!isLoggedIn && !to.meta.noAuth) {
            return next({ name: "auth-login" });
        }
    }

    return next();
});

export default router;
