export default [
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/settings/SettingsView.vue"),
        meta: {},
    },
    {
        path: "/settings/language",
        name: "settings-language",
        component: () => import("@/views/settings/pages/LanguageView.vue"),
        meta: {},
    },
    {
        path: "/settings/goal",
        name: "settings-goal",
        component: () => import("@/views/settings/pages/GoalView.vue"),
        meta: {},
    },
    {
        path: "/settings/diet",
        name: "settings-diet",
        component: () => import("@/views/settings/pages/DietView.vue"),
        meta: {},
    },
    {
        path: "/settings/allergen",
        name: "settings-allergen",
        component: () => import("@/views/settings/pages/AllergenView.vue"),
        meta: {},
    },
    {
        path: "/settings/meal",
        name: "settings-meal",
        component: () => import("@/views/settings/pages/MealView.vue"),
        meta: {},
    },
    {
        path: "/settings/birthdate",
        name: "settings-birthdate",
        component: () => import("@/views/settings/pages/BirthdateView.vue"),
        meta: {},
    },
    {
        path: "/settings/start-weight",
        name: "settings-start-weight",
        component: () => import("@/views/settings/pages/WeightStartView.vue"),
        meta: {},
    },
    {
        path: "/settings/ideal-weight",
        name: "settings-ideal-weight",
        component: () => import("@/views/settings/pages/WeightIdealView.vue"),
        meta: {},
    },
    {
        path: "/settings/cooking-time",
        name: "settings-cooking-time",
        component: () => import("@/views/settings/pages/CookingTimeView.vue"),
        meta: {},
    },
    {
        path: "/settings/physical-activity",
        name: "settings-physical-activity",
        component: () => import("@/views/settings/pages/PhysicalActivityView.vue"),
        meta: {},
    },
    {
        path: "/settings/unit-system",
        name: "settings-unit-system",
        component: () => import("@/views/settings/pages/UnitSystemView.vue"),
        meta: {},
    },
    {
        path: "/settings/change-password",
        name: "settings-change-password",
        component: () => import("@/views/password/ChangePasswordView.vue"),
        meta: {},
    },
    {
        path: "/settings/plan",
        name: "settings-plan",
        component: () => import("@/views/settings/pages/PlanView.vue"),
        meta: {},
    },
];
