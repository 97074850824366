import api from "@/api";

const state = () => ({
    caloriesBurned: 0,
    caloriesStep: 0,

    index: 0,

    paused: false,

    time: 0,
    timeTotal: 0,
    timeRemains: 0,

    finished: [],
});

export default {
    namespaced: true,
    state: {
        training: {
            exercises: [],
        },
        state: {
            ...state(),
        },
        player: null,
    },
    mutations: {
        setState(state, payload) {
            state.state = payload;
        },
        setPlayer(state, payload) {
            state.player = payload;
        },
        setTraining(state, payload) {
            state.training = payload;
        },
    },
    getters: {
        state: (state) => state.state,
        player: (state) => state.player,
        training: (state) => state.training,
    },
    actions: {
        fetchTraining(context, id) {
            return new Promise((resolve, reject) => {
                api.get(`/training/${id}`)
                    .then((res) => {
                        context.commit("setTraining", res.data);
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        resetState(context) {
            context.commit("setState", {
                ...state(),
            });
        },
    },
};
