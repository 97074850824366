const conversion = {
    kg: {
        mcg: 1000000000,
        mg: 1000000,
        g: 1000,
        oz: 35.274,
        lb: 2.20462,
        st: 0.15747285713508,
    },
    g: {
        kg: 0.001,
        mcg: 1000000.0,
        mg: 1000.0,
        oz: 0.035274,
        lb: 0.00220462,
        st: 0.000157473,
    },
    oz: {
        kg: 0.028349,
        mcg: 28349492.544083,
        mg: 28349.492544084,
        g: 28.349492544,
        lb: 0.0625,
        st: 0.004464,
    },
    lb: {
        kg: 0.453593,
        mcg: 453592909.43564,
        mg: 453592.90943564,
        g: 453.592909436,
        oz: 16,
        st: 0.071429,
    },
    m: {
        mm: 1000,
        cm: 100,
        in: 39.3701,
        ft: 3.28084,
        yd: 1.09361,
    },
    cm: {
        m: 0.01,
        mm: 10.0,
        in: 0.393701,
        ft: 0.0328084,
        yd: 0.0109361,
    },
    in: {
        m: 0.0254,
        mm: 25.399986284,
        cm: 2.539998628,
        ft: 0.083333,
        yd: 0.027778,
    },
    ft: {
        m: 0.3048,
        mm: 304.799990246,
        cm: 30.479999025,
        in: 12,
        yd: 0.333333,
    },
    "fl oz": {
        l: 0.0295735,
        ml: 29.5735,
    },
    l: {
        "fl oz": 33.814,
    },
    ml: {
        "fl oz": 0.033814,
    },
};

const unitSystemMap = {
    // Metric to US
    us: {
        g: "oz",
        cm: "in",
        m: "ft",
        kg: "lb",
        ml: "fl oz",
        l: "fl oz",
    },

    // US to metric
    metric: {
        in: "cm",
        oz: "g",
        ft: "m",
        lb: "kg",
        "fl oz": "l",
    },
};

export const convert = (value, from, to) => {
    if (!from || !to) {
        throw new Error(`Params from(${from}), to(${to}) must not be undefined.`);
    }

    if (!conversion[from]) {
        throw new Error("Param from must be one of following: " + Object.keys(conversion).join(", "));
    }

    const same = from === to;

    if (!conversion[from][to] && !same) {
        throw new Error(`${from} can't be converted to ${to}. Only to: ` + Object.keys(conversion[from]).join(", "));
    }

    return value * (same ? 1 : conversion[from][to]);
};

export const convertTo = (value, from, unitSystem) => {
    const unitTo = unitSystemMap[unitSystem][from] || from;

    return {
        amount: convert(value, from, unitTo),
        unit: unitTo,
    };
};
