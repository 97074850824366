import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex, axios);

import app from "@/store/app";
import list from "@/store/list";
import food from "@/store/food";
import workout from "@/store/workout";
import stripe from "@/store/stripe"

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        app: app,
        food: food,
        list: list,
        workout: workout,
        stripe: stripe,
    },
});
