export default [
    {
        id: 1,
        code: "metric",
        name: "Metric",
        desc: "kg, cm, ml",
    },
    {
        id: 2,
        code: "us",
        name: "US",
        desc: "pound, inch, ounce",
    },
];
