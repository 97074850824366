<template>
    <div class="bf-sidebar">
        <div class="bf-sidebar__header">
            <slot />
        </div>

        <div class="bf-sidebar__nav">
            <div v-for="item in items" :key="item.path" class="bf-sidebar__item" :class="classState(item)">
                <a v-if="item.click || item.path" class="bf-sidebar__link" @click="onLinkClick(item)">
                    <component v-if="item.icon" :is="item.icon" class="bf-sidebar__item-icon" />

                    <span class="bf-sidebar__item-text">{{ $t(item.title) }}</span>
                </a>

                <div v-else>
                    <component v-if="item.icon" :is="item.icon" class="bf-sidebar__item-icon" />

                    <span class="bf-sidebar__item-text">{{ $t(item.title) }}</span>
                </div>

                <Lock :show="lock(item)" right />
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/components/bf-sidebar.scss";

import Lock from "@/components/Lock";

import app from "@/mixins/app";

export default {
    name: "Sidebar",
    mixins: [app],
    components: { Lock },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    computed: {},
    created() {},
    data: () => ({}),
    methods: {
        onLinkClick(item) {
            if (this.lock(item)) {
                return null;
            }

            if (item.click) {
                item.click();
            }

            if (item.path) {
                this.$router.push(item.path);
            }
        },
        lock(item) {
            const lockable = item.meta.lockable !== undefined ? !!item.meta.lockable : true;

            return lockable && this.user.accessDenied;
        },
        classState(item) {
            const active = this.$route.path.includes(item.path);

            return {
                "--disabled": this.lock(item),
                "--active": active,
                "--soon": item.meta.soon && !this.user.accessDenied,
            };
        },
    },
};
</script>

<style scoped></style>
