export default class Register {
    setQuizProgress(value) {
        const data = this.getCredentials();

        const now = new Date();

        const item = {
            quiz: value,
            expires_at: now.getTime() + 6000000,
        };

        if (data) {
            item.credentials = data;
        }

        localStorage.setItem("register", JSON.stringify(item));
    }

    setCredentials(value) {
        const data = this.getQuizProgress();

        const now = new Date();

        const item = {
            credentials: value,
            expires_at: now.getTime() + 6000000,
        };

        if (data) {
            item.quiz = data;
        }

        localStorage.setItem("register", JSON.stringify(item));
    }

    getCredentials() {
        const json = localStorage.getItem("register");

        if (!json) {
            return null;
        }

        const data = JSON.parse(json);
        const now = new Date();

        if (now.getTime() > data.expires_at) {
            localStorage.removeItem("register");
            return null;
        }

        return data.credentials;
    }

    getQuizProgress() {
        const json = localStorage.getItem("register");

        if (!json) {
            return null;
        }

        const data = JSON.parse(json);
        const now = new Date();

        if (now.getTime() > data.expires_at) {
            localStorage.removeItem("register");
            return null;
        }

        return data.quiz;
    }

    clear() {
        localStorage.removeItem("register");
    }
}
