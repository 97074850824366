import api from "@/api";

/**
 * @property {Array} foods
 */
export default {
    namespaced: true,
    state: {
        loading: false,

        meal: [],
        dailyNutrients: {},

        foodTypeId: 1,
        foods: [],
        favoriteFoods: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        foods(state) {
            return state.foods;
        },
        foodTypeId(state) {
            return state.foodTypeId;
        },
        meal(state) {
            return state.meal;
        },
        favoriteFoods(state) {
            return state.favoriteFoods;
        },
        dailyNutrients(state) {
            return state.dailyNutrients;
        },
    },
    mutations: {
        setMeal(state, payload) {
            state.meal = payload;
        },
        setDailyNutrients(state, payload) {
            state.dailyNutrients = payload;
        },
        setLoading(state, payload) {
            state.loading = !!payload;
        },
        setFavoriteFoods(state, payload) {
            state.favoriteFoods = payload;
        },
        setFoodTypeId(state, payload) {
            state.foodTypeId = payload;
        },
        setFoods(state, payload) {
            state.foods = payload;
        },
    },
    actions: {
        fetchFavoriteFoods(context, type_id) {
            const query = type_id ? `?type_id=${type_id}` : "";

            context.commit("setLoading", true);

            return new Promise((resolve, reject) => {
                api.get(`/food/favorite${query}`)
                    .then((res) => {
                        context.commit("setFavoriteFoods", res.data);
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },
        fetchFoods(context, params = {}) {
            const type_id = context.foodTypeId;

            context.commit("setLoading", true);

            return new Promise((resolve, reject) => {
                api.get(`/food/search`, {
                    params: {
                        type_id: type_id || null,
                        ...params,
                    },
                })
                    .then((res) => {
                        const data = Array.isArray(res.data) ? res.data : [];

                        if (typeof res.data === "string") {
                            reject(false);
                        } else {
                            context.commit("setFoods", data);
                            resolve(res.data);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },
    },
};
