import axios from "axios";
import { isUserLoggedIn, getAccessToken } from "@/auth";

import router from "@/router";

const url = window.location.hostname === "localhost" ? "http://stripe.loc" : window.location.origin;

const accessToken = getAccessToken();

const api = axios.create({
    baseURL: `${url}/api/`,
    headers: {
        //'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        // Get token from localStorage
        const accessToken = getAccessToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;

        if (response && response.status === 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("access_token");

            router.push({ name: "auth-login" });
        }

        return Promise.reject(error);
    }
);

export default api;
