import i18n from "@/libs/i18n/index.js";

export default [
    {
        id: 1,
        name: i18n.t("quiz.2.option.lose-weight"),
        desc: "Without strict diets and grueling workouts",
        code: "apple",
    },
    {
        id: 2,
        name: i18n.t("quiz.2.option.well-being"),
        desc: "Without strict diets and grueling workouts",
        code: "money",
    },
    {
        id: 3,
        name: i18n.t("quiz.2.option.increase-energy"),
        desc: "Without strict diets and grueling workouts",
        code: "zipper",
    },
    {
        id: 4,
        name: i18n.t("quiz.2.option.healthy-eating"),
        desc: "Without strict diets and grueling workouts",
        code: "salad",
    },
];
