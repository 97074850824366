export default {
    props: {
        // DEFAULT THEMES
        primary: {
            type: Boolean,
            default: false,
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        info: {
            type: Boolean,
            default: false,
        },
        danger: {
            type: Boolean,
            default: false,
        },
        warning: {
            type: Boolean,
            default: false,
        },

        // STATES
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },

        // STYLES
        borderless: {
            type: Boolean,
            default: false,
        },

        // SIZES
        sm: {
            type: Boolean,
            default: false,
        },
        md: {
            type: Boolean,
            default: false,
        },
        lg: {
            type: Boolean,
            default: false,
        },
        xl: {
            type: Boolean,
            default: false,
        },
    },
    created() {},
    computed: {
        classMap() {
            return {
                "--primary": this.primary,
                "--secondary": this.secondary,
                "--success": this.success,
                "--info": this.info,
                "--danger": this.danger,
                "--warning": this.warning,

                "--active": this.active,
                "--disabled": this.disabled,
                "--borderless": this.borderless,

                "--sm": this.sm,
                "--md": this.md,
                "--lg": this.lg,
                "--xl": this.xl,
            };
        },
    },
    data: () => ({}),
    methods: {},
    mounted() {},
};
