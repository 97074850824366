import SignLayout from "@/layouts/SignLayout";
import LandingLayout from "@/layouts/LandingLayout";
import QuizLayout from "@/layouts/QuizLayout";
import SimpleLayout from "@/layouts/SimpleLayout";

import settings from "@/routes/settings";

import { isUserLoggedIn, register } from "@/auth";
import salesRoutes from "@/routes/salesRoutes";

const beforeEnter = (to, from, next) => {
    const isLoggedIn = isUserLoggedIn();

    if (isLoggedIn) {
        return next({ name: "dashboard" });
    }

    return next();
};

export default [
    {
        path: "/",
        name: "app",
        component: () => import("@/views/main/MainView.vue"),
        meta: {
            noAuth: true,
            layout: LandingLayout,
        },
    },
    ...salesRoutes,

    {
        path: "*",
        name: "pageNotFound",
        component: () => import("@/views/errors/PageNotFoundView.vue"),
        meta: {},
    },

    {
        path: "*",
        name: "link-expired",
        component: () => import("@/views/errors/LinkExpiredView.vue"),
        meta: {
            noAuth: true,
        },
    },
    ...settings,
    // SIGN
    {
        path: "/login",
        name: "auth-login",
        component: () => import("@/views/login/LoginView.vue"),
        meta: {
            layout: SignLayout,
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/password/ForgotPasswordView.vue"),
        meta: {
            layout: SignLayout,
            noAuth: true,
        },
    },

    // MAIN LAYOUT START
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/DashboardView.vue"),
        meta: {},
    },
    // DASHBOARD ROUTES START
    {
        path: "/dashboard/weight-control",
        name: "weight-control",
        component: () => import("@/views/weight-control/WeightControlView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/body-measurement",
        name: "body-measurement",
        component: () => import("@/views/body-measurement/BodyMeasurementView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/add-water",
        name: "add-water",
        component: () => import("@/views/water/AddWaterView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/add-steps",
        name: "add-steps",
        component: () => import("@/views/steps/AddStepsView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/add-food",
        name: "add-food",
        component: () => import("@/views/food/AddFoodView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/add-meal",
        name: "add-meal",
        component: () => import("@/views/food/AddMealView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/add-fast-food",
        name: "add-fast-food",
        component: () => import("@/views/food/AddFastFoodView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/add-custom-food",
        name: "add-custom-food",
        component: () => import("@/views/food/AddCustomFoodView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/favorites",
        name: "favorites",
        component: () => import("@/views/favorites/FavoritesView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/another-dish",
        name: "another-dish",
        component: () => import("@/views/food/AnotherDishView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/last-cooked",
        name: "last-cooked",
        component: () => import("@/views/food/LastCookedView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/dish-list",
        name: "dish-list",
        component: () => import("@/views/dish/DishListView.vue"),
        meta: {},
    },
    {
        path: "/food/:id",
        name: "food",
        props: { ratio: 1 },
        component: () => import("@/views/food/FoodView.vue"),
        meta: {},
    },
    {
        path: "/replace-food",
        name: "replace-food",
        component: () => import("@/views/food/ReplaceFoodView.vue"),
        props: { food_id: null, calories: null, date: null },
        beforeEnter: (to, from, next) => {
            if (from.name !== "recipe") {
                return next({ name: "diets" });
            }

            const { calories, date } = to.params;

            if (!calories || !date) {
                return next({ name: "diets" });
            }

            return next();
        },
        meta: {},
    },
    {
        path: "/recipe/:id",
        name: "recipe",
        props: { ratio: 1, date: null },
        component: () => import("@/views/recipe/RecipeView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/scan",
        name: "scan",
        component: () => import("@/views/scan/ScanView.vue"),
        meta: {},
    },
    {
        path: "/grocery-list",
        name: "grocery-list",
        component: () => import("@/views/grocery-list/GroceryListView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/upload-photo",
        name: "upload-photo",
        component: () => import("@/views/photo/UploadPhotoView.vue"),
        meta: {},
    },
    {
        path: "/dashboard/make-photo",
        name: "make-photo",
        component: () => import("@/views/photo/MakePhotoView.vue"),
        meta: {},
    },

    // DASHBOARD ROUTES END
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/ProfileView.vue"),
        meta: {},
    },
    {
        path: "/payment-result",
        name: "payment-result",
        component: () => import("@/views/payment/PaymentResultView.vue"),
        meta: {
            layout: SimpleLayout,
            noAuth: true,
        },
    },
    {
        path: "/plans",
        name: "plans",
        component: () => import("@/views/payment/PlansView.vue"),
        meta: {},
    },
    {
        path: "/payment",
        name: "payment",
        component: () => import("@/views/payment/PaymentView.vue"),
        // props: { price_id: null, },
        beforeEnter: (to, from, next) => {
            const credentials = register.getCredentials();

            const { price_id, token } = to.query;

            const passes = isUserLoggedIn() || token;

            if (!price_id || !passes) {
                return next(from);
            }

            return next();
        },
        meta: {
            layout: LandingLayout,
            noAuth: true,
        },
    },
    {
        path: "/create-password",
        name: "create-password",
        component: () => import("@/views/password/CreatePasswordView.vue"),
        beforeEnter: (to, from, next) => {
            if (isUserLoggedIn()) {
                return next({ name: "dashboard" });
            }

            if (!to.query.token) {
                return next({ name: "app" });
            }

            return next();
        },
        meta: {
            noAuth: true,
            layout: SignLayout,
        },
    },

    {
        path: "/trainings",
        name: "trainings",
        component: () => import("@/views/training/TrainingsView.vue"),
        meta: {},
    },
    {
        path: "/training/:id",
        name: "training",
        component: () => import("@/views/training/TrainingView.vue"),
        meta: {},
    },
    {
        path: "/workout/:id",
        name: "workout",
        component: () => import("@/views/training/WorkoutView.vue"),
        meta: {},
    },
    {
        path: "/diets",
        name: "diets",
        component: () => import("@/views/diet/DietView.vue"),
        meta: {},
    },
    {
        path: "/challenges",
        name: "challenges",
        component: () => import("@/views/challenge/ChallengeView.vue"),
        meta: {},
    },
    {
        path: "/menu-for-children",
        name: "menu-for-children",
        component: () => import("@/views/menu-for-children/MenuForChildrenView"),
        meta: {},
    },
    {
        path: "/ebooks",
        name: "ebooks",
        component: () => import("@/views/ebooks/EbooksView"),
        meta: {},
    },
    {
        path: "/coins",
        name: "coins",
        component: () => import("@/views/coins/CoinsView"),
        meta: {},
    },
    {
        path: "/bring-a-friend",
        name: "bring-a-friend",
        component: () => import("@/views/bring-a-friend/BringAFriendView"),
        meta: {},
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/privacy/PrivacyView"),
        meta: {
            layout: LandingLayout,
            noAuth: true,
        },
    },
    {
        path: "/terms-and-conditions",
        name: "terms-and-conditions",
        component: () => import("@/views/terms-and-conditions/TermsAndConditionsView"),
        meta: {
            layout: LandingLayout,
            noAuth: true,
        },
    },
    {
        path: "/refund-policy",
        name: "refund-policy",
        component: () => import("@/views/refund-policy/RefundPolicyView"),
        meta: {
            layout: LandingLayout,
            noAuth: true,
        },
    },
    {
        path: "/cookies",
        name: "cookies",
        component: () => import("@/views/cookies/CookiesView"),
        meta: {
            layout: LandingLayout,
            noAuth: true,
        },
    },

    // MAIN LAYOUT END
];
