import router from "@/router";

import Register from "@/models/Register";
import User from "@/models/User";

export const isUserLoggedIn = () => {
    return localStorage.getItem("user") && localStorage.getItem("access_token");
};

/**
 * @return {User}
 */
export const getUser = () => {
    const json = localStorage.getItem("user");
    const data = json ? JSON.parse(json) : {};

    return new User(data);
};

export const getAccessToken = () => localStorage.getItem("access_token");

export const logout = () => {
    fetch(`/api/auth/logout`, { method: "POST" }).finally(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        router.push("/login");
    });
};

export const register = new Register();
