export default class Model {
    constructor(data) {
        const item = JSON.parse(JSON.stringify(data));
        this.original = { ...item };

        this.loading = false;
        this.loaded = false;
    }

    fill(data = {}) {
        for (const key in this.original) {
            if (!this.original.hasOwnProperty(key)) continue;

            if (!this[key] && data.hasOwnProperty(key) && !["original", "loading", "loaded"].includes(key)) {
                this[key] = data[key];
            }
        }
    }

    reset() {
        for (const key in this.original) {
            if (!this.original.hasOwnProperty(key)) continue;

            if (!["original", "loading", "loaded"].includes(key)) {
                this[key] = "";
            }
        }
    }
}
