export default [
    {
        value: 18,
        title: "18-25",
        desc: "18-25",
        img: "age-1",
    },
    {
        value: 26,
        title: "26-35",
        desc: "26-35",
        img: "age-2",
    },
    {
        value: 36,
        title: "36-45",
        desc: "36-45",
        img: "age-3",
    },
    {
        value: 46,
        title: "46-60",
        desc: "46-60",
        img: "age-4",
    },
];
