import api from "@/api";

import db from "@/db";
import Plan from "@/models/Plan";

function ajax(url, context, method, getter) {
    return new Promise((resolve, reject) => {
        api.get(url)
            .then(({ data }) => {
                if (Array.isArray(data) || typeof data === "object") {
                    context.commit(method, data);
                    resolve(db[getter]);
                } else {
                    context.commit(method, db[getter]);
                    resolve(db[getter]);
                }
            })
            .catch(() => {
                context.commit(method, db[getter]);
                resolve(db[getter]);
            });
    });
}

export default {
    namespaced: true,
    state: {
        activities: [],
        allergens: [],
        cooking_times: [],
        meals: [],
        diets: [],
        genders: [],
        goals: [],
        units: {
            mass: [],
            length: [],
        },
        unitSystems: [],
        locales: [],

        parameters: {},
        plans: {},
    },
    mutations: {
        setActivities(state, payload) {
            state.activities = payload;
        },
        setAllergens(state, payload) {
            state.allergens = payload;
        },
        setCookingTimes(state, payload) {
            state.cooking_times = payload;
        },
        setMeals(state, payload) {
            state.meals = payload;
        },
        setDiets(state, payload) {
            state.diets = payload;
        },
        setGenders(state, payload) {
            state.genders = payload;
        },
        setGoals(state, payload) {
            state.goals = payload;
        },
        setLocales(state, payload) {
            state.locales = payload;
        },
        setUnits(state, payload) {
            state.units = payload;
        },
        setUnitSystems(state, payload) {
            state.unitSystems = payload;
        },
        setParameters(state, payload) {
            state.parameters = payload;
        },
        setPlans(state, payload = []) {
            const plans = [];

            for (const item of payload) {
                const plan = new Plan(item);
                plan.fill(item);
                plans.push(plan);
            }

            state.plans = plans;
        },
    },
    getters: {
        activities(state) {
            return state.activities;
        },
        allergens(state) {
            return state.allergens;
        },
        cookingTimes(state) {
            return state.cooking_times;
        },
        meals(state) {
            return state.meals;
        },
        diets(state) {
            return state.diets;
        },
        genders(state) {
            return state.genders;
        },
        goals(state) {
            return state.goals;
        },
        locales(state) {
            return state.locales;
        },
        units(state) {
            return state.units;
        },
        unitSystems(state) {
            return state.unitSystems;
        },
        parameters(state) {
            return state.parameters;
        },
        /**
         * @param state
         * @return {Array<Plan>}
         */
        plans(state) {
            return state.plans;
        },
    },
    actions: {
        fetchActivities(context) {
            return ajax("/physical-activity", context, "setActivities", "activities");
        },
        fetchAllergens(context) {
            return ajax("/allergens", context, "setAllergens", "allergens");
        },
        fetchCookingTimes(context) {
            return ajax("/cooking-times", context, "setCookingTimes", "cookingTimes");
        },
        fetchMeals(context) {
            return ajax("/meals", context, "setMeals", "meals");
        },
        fetchDiets(context) {
            return ajax("/diets", context, "setDiets", "diets");
        },
        fetchGoals(context) {
            return ajax("/goals", context, "setGoals", "goals");
        },
        fetchLocales(context) {
            return ajax("/locales", context, "setLocales", "locales");
        },
        fetchGenders(context) {
            return ajax("/genders", context, "setGenders", "genders");
        },
        fetchUnits(context) {
            return ajax("/units", context, "setUnits", "units");
        },
        fetchUnitSystems(context) {
            return ajax("/unit-systems", context, "setUnitSystems", "unitSystems");
        },
        fetchParameters(context) {
            return ajax("/parameters", context, "setParameters", "parameters");
        },
        async fetchPlans(context, datum = {}) {
            try {
                const { data } = await api.post("plans", datum);
                context.commit("setPlans", data);
                return data;
            } catch (e) {
                return e;
            }
        },
    },
};
