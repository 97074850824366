<template>
    <div class="landing-layout">
        <slot />
    </div>
</template>

<script>
export default {
    name: "LandingLayout",
    components: {},
};
</script>

<style lang="scss"></style>
