<template>
    <svg class="svg-icon --fill" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M17.8166 4.80042C17.7882 4.69401 17.7389 4.59429 17.6717 4.50704C17.6045 4.41979 17.5206 4.34673 17.425 4.29209L13.0916 1.79209C12.997 1.73664 12.8924 1.70042 12.7837 1.68549C12.6751 1.67056 12.5646 1.67723 12.4585 1.7051C12.3525 1.73297 12.2529 1.7815 12.1657 1.8479C12.0784 1.91431 12.0051 1.99729 11.95 2.09209L9.99996 5.47542L8.04996 2.09209C7.99482 1.99729 7.92152 1.91431 7.83425 1.8479C7.74698 1.7815 7.64746 1.73297 7.5414 1.7051C7.43534 1.67723 7.32482 1.67056 7.21617 1.68549C7.10753 1.70042 7.0029 1.73664 6.90829 1.79209L2.57496 4.29209C2.47932 4.34673 2.39546 4.41979 2.32823 4.50704C2.261 4.59429 2.21174 4.69401 2.18329 4.80042C2.12814 5.01163 2.15806 5.23604 2.26662 5.42542L5.59996 11.1171C5.22808 11.7984 5.02249 12.5579 4.99996 13.3338C4.99797 14.0907 5.16786 14.8381 5.4968 15.5198C5.82575 16.2015 6.30517 16.7996 6.89892 17.2691C7.49267 17.7385 8.18524 18.067 8.92443 18.2298C9.66362 18.3926 10.4301 18.3855 11.1662 18.2089C11.9022 18.0324 12.5885 17.6911 13.1734 17.2107C13.7583 16.7302 14.2265 16.1233 14.5427 15.4356C14.8589 14.7479 15.0149 13.9974 14.9988 13.2407C14.9827 12.4839 14.7951 11.7407 14.45 11.0671L17.7 5.43375C17.7604 5.3412 17.8019 5.23754 17.8219 5.12881C17.842 5.02008 17.8402 4.90845 17.8166 4.80042ZM4.16662 5.31709L7.04996 3.65042L9.73329 8.33375C8.58947 8.40442 7.50561 8.86977 6.66662 9.65042L4.16662 5.31709ZM9.99996 16.6671C9.1159 16.6671 8.26805 16.3159 7.64293 15.6908C7.01781 15.0657 6.66662 14.2178 6.66662 13.3338C6.66662 12.4497 7.01781 11.6019 7.64293 10.9767C8.26805 10.3516 9.1159 10.0004 9.99996 10.0004C10.884 10.0004 11.7319 10.3516 12.357 10.9767C12.9821 11.6019 13.3333 12.4497 13.3333 13.3338C13.3333 14.2178 12.9821 15.0657 12.357 15.6908C11.7319 16.3159 10.884 16.6671 9.99996 16.6671ZM13.3333 9.62542C12.8809 9.21218 12.3552 8.88715 11.7833 8.66709L10.9666 7.14209L12.9833 3.65042L15.8666 5.31709L13.3333 9.62542Z"
        />
    </svg>
</template>

<script>
export default {
    name: "MedalIcon",
};
</script>

<style scoped></style>
