export default [
    {
        id: 1,
        code: "dairy",
        name: "Dairy",
    },
    {
        id: 2,
        code: "egg",
        name: "Egg",
    },
    {
        id: 3,
        code: "gluten",
        name: "Gluten",
    },
    {
        id: 4,
        code: "grain",
        name: "Grain",
    },
    {
        id: 5,
        code: "peanut",
        name: "Peanut",
    },
    {
        id: 6,
        code: "wheat",
        name: "Wheat",
    },
    {
        id: 7,
        code: "sesame",
        name: "Sesame",
    },
    {
        id: 8,
        code: "shellfish",
        name: "Shellfish",
    },
    {
        id: 9,
        code: "soy",
        name: "Soy",
    },
    {
        id: 10,
        code: "sulfite",
        name: "Sulfite",
    },
    {
        id: 11,
        code: "tree-nut",
        name: "Tree Nut",
    },
    {
        id: 12,
        code: "seafood",
        name: "Seafood",
    },
];
