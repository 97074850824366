<template>
    <div class="sign-layout">
        <div class="sign-white-part">
            <div class="sign-white-part-content">
                <div class="sign-white-part__header">
                    <a href="/landing" class="sign-white-part__logo">
                        <img src="@/assets/img/sign/logo.svg" alt="BalanceFit" class="sign-white-part__logo-img" />
                    </a>
                </div>
                <div class="sign-white-part__body">
                    <slot />
                </div>
                <div class="sign-white-part__footer">© 2022 BalanceFit. All Rights Reserved</div>
            </div>
        </div>
        <div class="sign-bg">
            <h2 class="sign-bg__title">
                <span>Easy way to your</span>
                <span class="sign-bg__title--highlighted">Dream Body</span>
                <img src="@/assets/img/sign/circles.png" class="sign-bg__circles-img" />
                <img src="@/assets/img/sign/people.png" class="sign-bg__people-img" />
            </h2>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/views/sign-layout.scss";

export default {
    name: "SignLayout",
    components: {},
};
</script>

<style lang="scss"></style>
