import i18n from "@/libs/i18n/index.js";

export default [
    {
        id: 1,
        code: "nutrition",
        name: i18n.t("diet.nutrition.title"),
        desc: i18n.t("diet.nutrition.description"),
    },
    {
        id: 2,
        code: "ketogenic",
        name: i18n.t("diet.ketogenic.title"),
        desc: i18n.t("diet.ketogenic.description"),
    },
    {
        id: 3,
        code: "gluten-free",
        name: i18n.t("diet.gluten-free.title"),
        desc: i18n.t("diet.gluten-free.description"),
    },
    {
        id: 4,
        code: "paleo",
        name: i18n.t("diet.paleo.title"),
        desc: i18n.t("diet.paleo.description"),
    },
    {
        id: 5,
        code: "vegetarian",
        name: i18n.t("diet.vegetarian.title"),
        desc: i18n.t("diet.vegetarian.description"),
    },
    {
        id: 6,
        code: "vegan",
        name: i18n.t("diet.vegan.title"),
        desc: i18n.t("diet.vegan.description"),
    },
    {
        id: 7,
        code: "pescetarian",
        name: i18n.t("diet.pescatarian.title"),
        desc: i18n.t("diet.pescatarian.description"),
    },
];
